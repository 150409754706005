import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'e404',
	templateUrl: './e404.component.html',
	styleUrls: ['./e404.component.css'],
})
export class E404Component implements OnInit {
	constructor() {}

	ngOnInit(): void {}
}
