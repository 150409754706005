<top-bar />
<div class="p-wrapper">
	<h1>404</h1>
	<div class="row">
		<div class="col-sm-3"></div>
		<div class="col-sm-6">
			<img
				alt="A man is on a small boat looking up at an intimidating whale"
				src="assets/images/lost.png"
				class="lost"
			/>
			<p>
				Uh-oh! Looks like that page doesn't exist. Click the most relevant link
				in the navigation menu above to get back into the project.
			</p>
		</div>
	</div>
</div>
