<div class="row">
	<div class="col-md-12">
		<h1>About</h1>
		<div class="text-center">
			<ul class="tech-badges">
				<li>
					<a href="https://angular.io/" rel="noreferrer" target="_blank">
						<img
							src="assets/images/tech-badges/angular.png"
							alt="Angular"
							class="tech-badge"
						/>
					</a>
				</li>
				<li>
					<a
						href="https://html.spec.whatwg.org/"
						rel="noreferrer"
						target="_blank"
					>
						<img
							src="assets/images/tech-badges/html5.png"
							alt="HTML5"
							class="tech-badge"
						/>
					</a>
				</li>
				<li>
					<a
						href="https://www.w3.org/Style/CSS/"
						rel="noreferrer"
						target="_blank"
					>
						<img
							src="assets/images/tech-badges/css3.png"
							alt="CSS3"
							class="tech-badge"
						/>
					</a>
				</li>
				<li>
					<a href="https://getbootstrap.com/" rel="noreferrer" target="_blank">
						<img
							src="assets/images/tech-badges/bootstrap.png"
							alt="Bootstrap"
							class="tech-badge"
						/>
					</a>
				</li>
				<li>
					<a
						href="https://www.typescriptlang.org/"
						rel="noreferrer"
						target="_blank"
					>
						<img
							src="assets/images/tech-badges/ts.png"
							alt="TypeScript"
							class="tech-badge"
						/>
					</a>
				</li>
				<li>
					<a
						href="https://aws.amazon.com/what-is-aws/"
						rel="noreferrer"
						target="_blank"
					>
						<img
							src="assets/images/tech-badges/aws.svg"
							alt="Amazon Web Services (AWS)"
							class="tech-badge"
						/>
					</a>
				</li>
			</ul>
		</div>
		<p>
			This project is built in Angular using HTML5, CSS3, and TypeScript.
			Bootstrap is installed and utilized for a responsive UI. The site is
			hosted on. The full code for this site is available on
			<a
				href="https://github.com/kafkaesc/Call-Me-Ishmael"
				rel="noreferrer"
				target="_blank"
				>GitHub</a
			>.
		</p>
		<p>
			The
			<a
				href="https://fontawesome.com/v5.15/icons/phone"
				rel="noreferrer"
				target="_blank"
				>phone icon</a
			>
			used in the Call-Me-Ishmael badge was made available by Font Awesome under
			a
			<a
				href="https://fontawesome.com/license"
				rel="noreferrer"
				target="_blank"
			>
				Creative Commons Attribution 4.0 International License </a
			>.
		</p>
		<p>
			The Moby-Dick illustration on the 404 page is by I.W. Taber and sourced
			via
			<a
				href="https://commons.wikimedia.org/wiki/File:Moby_Dick_final_chase.jpg"
			>
				Wikimedia Commons </a
			>.
		</p>
		<p class="text-center">Built by Jared Hettinger</p>
		<div class="text-center">
			<ul class="social-links">
				<li>
					<a
						href="https://www.github.com/kafkaesc"
						rel="noreferrer"
						target="_blank"
					>
						<img
							src="assets/images/social/github.png"
							alt="GitHub"
							class="soc-icon"
						/>
					</a>
				</li>
				<li>
					<a
						href="https://www.twitter.com/_kafkaesc"
						rel="noreferrer"
						target="_blank"
					>
						<img
							src="assets/images/social/twitter.png"
							alt="Twitter"
							class="soc-icon"
						/>
					</a>
				</li>
				<li>
					<a
						href="https://instagram.com/kafkaesc"
						rel="noreferrer"
						target="_blank"
					>
						<img
							src="assets/images/social/instagram.png"
							alt="Instagram"
							class="soc-icon"
						/>
					</a>
				</li>
				<li>
					<a
						href="https://linkedin.com/in/jahettinger"
						rel="noreferrer"
						target="_blank"
					>
						<img
							src="assets/images/social/linkedin.png"
							alt="LinkedIn"
							class="soc-icon"
						/>
					</a>
				</li>
			</ul>
		</div>
	</div>
</div>
