<div class="chat-wrapper">
	<div class="bubble-block" [class]="side">
		<div class="bubble" [class]="side">
			<label>{{ textMessage.sender }}</label>
			<div class="bubble-bg">
				{{ textMessage.body }}
			</div>
		</div>
	</div>
</div>
